import { useMemoizedContentGetter } from 'utils/contentful'
import { Typography, LinkButton } from 'components/Contentful'
import { BLOCKS } from '@contentful/rich-text-types'
import Section from 'components/Section'
import styles from './CloserStatementSection.module.scss'
import { Grid } from '@achieve/sunbeam'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import useResponsiveMediaContent from 'hooks/useResponsiveMediaContent'

function CloserStatementSectionModule({ title, background, button, config = {} }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.xl)

  const backgroundImageContent = useResponsiveMediaContent({
    mediaContent: background?.mediaContent,
    middleMediaContent: background?.mobileMediaContent,
    mobileMediaContent: background?.mobileMediaContent,
  })

  return (
    <Section
      childContainerClassName={styles['closer-statement-child-section']}
      className={styles['closer-statement-section']}
      data-testid="closer-statement-section"
      backgroundImageContent={backgroundImageContent}
      backgroundImageAlt={background?.mediaAltText}
      backgroundObjectPosition={isMobile ? 'bottom' : 'top'}
    >
      <Grid
        container
        direction={isMobile ? 'column' : 'row'}
        alignItems="center"
        className={styles['outer-grid-container']}
        data-testid="closer-statement-outer-grid-container"
        data-right-content={config?.rightContent}
      >
        <Grid
          item
          xs={8}
          xl={6.5}
          className={styles['grid-item-text']}
          data-testid="closer-statement-grid-item-text"
        >
          <div className={styles['text-container']} data-testid="closer-statement-text-container">
            <Typography
              content={title?.textContent}
              variant="displayM10"
              fontWeight="bold"
              className={styles['title']}
            />
            <div className={styles['cta-container']}>
              {button && (
                <LinkButton
                  className={styles['button']}
                  content={button}
                  typographicOptions={{
                    variantOverride: {
                      [BLOCKS.PARAGRAPH]: isMobile ? 'displayXS30' : 'displayS11CTA',
                    },
                    fontWeight: 'bold',
                    'data-testid': 'closer-statement-button-text',
                  }}
                  size="medium"
                  color="secondary"
                  data-testid="closer-statment-button"
                  track={{
                    click_id: button.linkText?.content[0]?.content[0]?.value,
                    click_text: button.linkText?.content[0]?.content[0]?.value,
                    list_name: 'Product Page Closer',
                    click_url: button.linkHref,
                  }}
                />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </Section>
  )
}

function CloserStatementSection({ content }) {
  const { title, background, button, config } = useMemoizedContentGetter(content, [
    'title',
    'background',
    'button',
    'config',
  ])

  return (
    <CloserStatementSectionModule
      title={title}
      background={background}
      button={button}
      config={config?.jsonContent}
    />
  )
}
export { CloserStatementSection, CloserStatementSectionModule }
export default CloserStatementSection
